const { REACT_APP_IMAGE_GENERATOR_URL } = process.env;

const generateVersusURL = (watchedTeam) => {
    const second_team = {
        logo: "https://cdn.upshow.tv/live_event_assets/teams/everpassTeam.svg",
        color: '#007864'
    }

    const info = {
        sport_type: watchedTeam.sport,
        league_type: watchedTeam.league,
        first_team: watchedTeam.alias[0],
    };

    const thumbnailURL = new URL(`${REACT_APP_IMAGE_GENERATOR_URL}/generate_vs_image`);
    thumbnailURL.searchParams.append('sport', info.sport_type);
    thumbnailURL.searchParams.append('league', info.league_type);
    thumbnailURL.searchParams.append('subscription_type', 'peacock');
    thumbnailURL.searchParams.append('teamOneColor', watchedTeam.background_color);
    thumbnailURL.searchParams.append('teamTwoColor', second_team.color);
    const teamOneAssetUrl = Array.isArray(watchedTeam.logo) ? watchedTeam.logo[0] : watchedTeam.logo;
    const teamTwoAssetUrl = second_team.logo;

    return `${thumbnailURL.toString()}&teamOneAssetUrl=${teamOneAssetUrl}&teamTwoAssetUrl=${teamTwoAssetUrl}`;

}

const generateVersusURLCreate = (createdTeam) => {
    const second_team = {
        logo: "https://cdn.upshow.tv/live_event_assets/teams/everpassTeam.svg",
        color: '#007864'
    }

    const info = {
        sport_type: createdTeam.sport,
        league_type: createdTeam.league,
        first_team: createdTeam.alias[0],
    };

    const thumbnailURL = new URL(`${REACT_APP_IMAGE_GENERATOR_URL}/generate_vs_image`);
    thumbnailURL.searchParams.append('sport', info.sport_type);
    thumbnailURL.searchParams.append('league', info.league_type);
    thumbnailURL.searchParams.append('subscription_type', 'peacock');
    thumbnailURL.searchParams.append('teamOneColor', createdTeam.background_color);
    thumbnailURL.searchParams.append('teamTwoColor', second_team.color);
    const teamOneAssetUrl = createdTeam.logo;
    const teamTwoAssetUrl = second_team.logo;

    return `${thumbnailURL.toString()}&teamOneAssetUrl=${teamOneAssetUrl}&teamTwoAssetUrl=${teamTwoAssetUrl}`;
}

module.exports = { generateVersusURL, generateVersusURLCreate };