import { useState, useEffect } from 'react';
import { FormGroup, Label } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';

const AliasInput = ({ alias = [], onCompleted }) => {
    const [valueAlias, setValueAlias] = useState(alias.map(a => ({ label: a, value: a })));
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        onCompleted(valueAlias.map(a => a.value));
    }, [valueAlias]);

    const handleChange = (newValue, actionMeta) => {
        if (actionMeta?.action === 'create-option') {
            const trimmedValue = inputValue.trim();
            if (trimmedValue) {
                setValueAlias([...valueAlias, { label: trimmedValue, value: trimmedValue }]);
                setInputValue('');
            }
        } else {
            setValueAlias(newValue);
        }
    };
  
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleChange({ label: e.target.value, value: e.target.value }, { action: 'create-option' });
        }
    };
  
    return (
        <div style={{ width: '260px' }}>
            <FormGroup className="m-0">
                <Label for="new-alias" className="new-domain-label">
                    Write an alias and press <b>Enter</b> to add to the list
                </Label>
                <CreatableSelect
                    id="new-alias"
                    isMulti
                    value={valueAlias}
                    inputValue={inputValue}
                    onChange={handleChange}
                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    menuIsOpen={false}
                    onInputChange={(value) => setInputValue(value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Comma separated aliases"
                />
            </FormGroup>
        </div>
    );
};

export default AliasInput;