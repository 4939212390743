import { CardImg } from 'reactstrap';

const LiveEventImgCard = ({ thumbnail, fit, height }) => {
    const cardHeight = height ? height : 300;

    return <CardImg
        style={{
            height: fit ? cardHeight : 235,
            width: fit ? 'auto' : 350,
            objectFit: 'contain'
        }}
        src={thumbnail}
        alt="Card image cap"
    />
};

export default LiveEventImgCard;