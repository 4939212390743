import React, { useEffect, useState } from 'react';
import {
    Button,
    CardImg,
    Col,
    Row,
    Input,
    FormFeedback,
    Form,
} from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { AssetUploader, LeaguePicker, AliasInput } from 'Components';
import LiveEventImgCard from '../Sections/LiveEvent/LiveEventImgCard';
import { generateVersusURL } from '../libs/thumbnail_helper';

const TeamForm = ({ team = {}, onSubmit, onCancel }) => {

    const [thumbnail, setThumbnail] = useState(null);
    const [hasNFL, setHasNFL] = useState(false);

    const { watch, control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            display_name: team.display_name ?? null,
            background_color: team.background_color ?? null,
            background_image: team.background_image ?? null,
            logo: team.logo ?? null,
            alias: team.alias ?? [],
            state: team.state ?? null,
            leagues: team.leagueGenders ?? [],
            city: team.city ?? null,
        }
    });

    const watchedTeam = watch(['display_name', 'logo', 'background_color', 'background_image', 'alias', 'state', 'city', 'leagues']);

    useEffect(() => {
        setHasNFL(watchedTeam.leagues?.some(l => l.league.id === 7));
        if (watchedTeam.leagues.length && watchedTeam.background_color && watchedTeam.alias && watchedTeam.logo) {
            const versusTeamInfo = {
                background_color: watchedTeam.background_color,
                alias: watchedTeam.alias,
                logo: watchedTeam.logo,
                sport: watchedTeam.leagues[0].league.alias,
                league: watchedTeam.leagues[0].sport.alias,
            }
            const queryString = generateVersusURL(versusTeamInfo);
            setThumbnail(queryString);
        } else {
            setThumbnail(null);
        }
    }, [watchedTeam]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col className="mt-2">
                    <Row>
                        <div className="d-inline-block">
                            <span className="me-2">
                                <h5><strong>Team Name</strong></h5>
                                <Controller
                                    name="display_name"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ value, onChange }) => (
                                        <>
                                            <Input type="text" name="display_name" id="display_name" value={value} onChange={onChange} invalid={!!errors.display_name} />
                                            <FormFeedback>You need to choose a display name</FormFeedback>
                                        </>
                                    )}
                                >

                                </Controller>
                            </span>
                        </div>
                    </Row>
                    <Row>
                        <div className="d-inline-block">
                            <span className="me-2">
                                <h5><strong>Alias</strong></h5>
                                <Controller
                                    name="alias"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ value, onChange }) => (
                                        <>
                                            <AliasInput name="alias" id="alias" alias={value} onCompleted={onChange} invalid={!!errors.alias} />
                                            {!watchedTeam.alias.length && <span style={{ 'color': 'red' }}>At least one alias is required</span>}
                                        </>
                                    )}
                                >
                                </Controller>
                            </span>
                        </div>
                    </Row>
                </Col>
                <Col className="mt-2">
                    <Row>
                        <div className="d-inline-block">
                            <span className="me-2">
                                <h5><strong>Background Color</strong></h5>
                                <Controller
                                    name="background_color"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ value, onChange }) => (
                                        <>
                                            <Input type="color" name="background_color" id="background_color" value={value} onChange={onChange} />
                                            {!watchedTeam.background_color && <span style={{ 'color': 'red' }}>No background color selected</span>}
                                            <FormFeedback>You need to choose a team color</FormFeedback>
                                        </>
                                    )}
                                >

                                </Controller>
                                <div style={{ color: watchedTeam.background_color ?? 'black' }}><b>{watchedTeam.background_color}</b></div>
                            </span>
                        </div>
                    </Row>
                    <Row>
                        <div className="d-inline-block">
                            <span className="me-2">
                                <h5><strong>Leagues</strong></h5>
                                <Controller
                                    name="leagues"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ onChange, value }) => (
                                        <LeaguePicker onChange={onChange} teamRelatedLeagues={value} />
                                    )}
                                />
                                <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                                    {!watchedTeam.leagues.length && <span style={{ 'color': 'red' }}>At least one league has to be selected</span>}
                                </div>
                            </span>
                        </div>
                    </Row>
                </Col>
                <Col className="mt-2">
                    <div className="d-inline-block">
                        <span className="me-2">
                            <h5><strong>Thumbnail</strong></h5>
                            {thumbnail && <div><LiveEventImgCard thumbnail={thumbnail} height={135} fit={true} /></div>}
                        </span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="mt-2">
                    <div className="d-inline-block">
                        <span className="me-2">
                            <h5><strong>Logo</strong></h5>
                            <Controller
                                control={control}
                                rules={{ required: true }}
                                name="logo"
                                render={({ onChange, value }) => {
                                    return (
                                        <>
                                            {value && <div><LiveEventImgCard thumbnail={value} height={135} fit /></div>}
                                            <AssetUploader
                                                accept="image/*"
                                                file={value}
                                                invalid={!!errors.logo}
                                                setFile={onChange}
                                                required={false}
                                                isUploading={() => {
                                                }}
                                                path="live_event_assets/teams"
                                                allowReupload={true}
                                            />
                                            <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                                                {!watchedTeam.logo && <span style={{ 'color': 'red' }}>A logo is needed</span>}
                                            </div>
                                        </>
                                    );
                                }}
                            />
                        </span>
                    </div>
                </Col>
            </Row>
            {hasNFL && <>
                <Row className="mt-2">
                    <span className="me-2">
                        <h5><strong>NFL only</strong></h5>
                    </span>
                </Row>
                <Row className="mt-2">
                    <Col className="mr-2">
                        <div className="d-inline-block">
                            <span className="me-2">
                                <h6><strong>Background Image</strong></h6>
                                <Controller
                                    control={control}
                                    name="background_image"
                                    render={({ onChange, value }) => {
                                        return (
                                            <>
                                                {value && <div>
                                                    <CardImg
                                                        style={{
                                                            height: 'auto',
                                                            width: 350,
                                                            objectFit: 'contain'
                                                        }}
                                                        src={value}
                                                        alt="Card image cap"
                                                    />
                                                </div>}
                                                <AssetUploader
                                                    style={{ width: '200px' }}
                                                    accept="image/*"
                                                    file={value}
                                                    setFile={onChange}
                                                    required={false}
                                                    isUploading={() => {
                                                    }}
                                                    path="sunday-ticket/backgrounds"
                                                    allowReupload={true}
                                                />
                                                <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                                                    {!watchedTeam.background_image && <span style={{ 'color': 'red' }}>A background image is needed</span>}
                                                </div>
                                            </>
                                        );
                                    }}
                                />
                            </span>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-inline-block">
                            <span className="me-2">
                                <h6><strong>State</strong></h6>
                                <Controller
                                    name="state"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ value, onChange }) => (
                                        <>
                                            <Input type="text" name="state" id="state" value={value} onChange={onChange} invalid={!!errors.state} />
                                            <FormFeedback>You need to choose a state</FormFeedback>
                                        </>
                                    )}
                                >
                                </Controller>
                            </span>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-inline-block">
                            <span className="me-2">
                                <h6><strong>City</strong></h6>
                                <Controller
                                    name="city"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ value, onChange }) => (
                                        <>
                                            <Input type="text" name="city" id="city" value={value} onChange={onChange} invalid={!!errors.city} />
                                            <FormFeedback>You need to choose a city</FormFeedback>
                                        </>
                                    )}
                                >

                                </Controller>
                            </span>
                        </div>
                    </Col>
                </Row>
            </>}
            <Button color={'link'} onClick={onCancel}>Cancel</Button>
            <Button color={'primary'}>Submit</Button>
        </Form>
    );
};

export default TeamForm;